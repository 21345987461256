var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-center px-3"},[_c('div',{staticClass:"mw--100"},[_c('div',{staticClass:"flex-center flex-column text-center"},[_c('div',{staticClass:"pb-3"},[_vm._v(_vm._s(_vm.$t('groups.register.welcome')))]),_c('div',{staticClass:"pb-5 text-pre"},[_vm._v(_vm._s(_vm.$t('groups.register.remind')))]),(_vm.registerType !== _vm.eGroupRegisterType.USER_TEPM_REGISTER)?_c('div',{staticClass:"pb-5 mw--60"},[_vm._v(_vm._s(_vm.$t('groups.register.remind_2')))]):_vm._e(),(_vm.registerType === _vm.eGroupRegisterType.ADMIN_REGISTER)?_c('div',{staticClass:"pb-1 text-pre text-danger fwb"},[_vm._v(_vm._s(_vm.$t('groups.register.remind_admin_register')))]):_vm._e(),(_vm.registerType === _vm.eGroupRegisterType.USER_TEPM_REGISTER)?_c('div',{staticClass:"pb-1 text-pre text-danger fwb"},[_vm._v(_vm._s(_vm.$t('groups.register.mail_no_reply')))]):_vm._e(),(_vm.registerType === _vm.eGroupRegisterType.USER_TEPM_REGISTER)?_c('div',{staticClass:"pb-1 text-pre"},[_vm._v(_vm._s(_vm.$t('groups.register.remind_qr_2')))]):_vm._e()]),_c('ValidationObserver',{staticClass:"form d-flex flex-column px-3",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{staticClass:"register-form py-3"},[(_vm.registerType === _vm.eGroupRegisterType.ADMIN_REGISTER)?_c('div',{staticClass:"employee-id pt-3"},[_c('ValidationProvider',{attrs:{"rules":{ required: true, max: 255, no_script_tag: true },"name":"employee_code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('common.form.employee_id'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t('common.form.require'))+" ")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newMember.employee_number),expression:"newMember.employee_number"}],staticClass:"form-control rounded-0 h--10",class:{ 'border-danger': errors[0] },attrs:{"placeholder":_vm.$t('common.form.employee_id'),"type":"text","name":"employee_code"},domProps:{"value":(_vm.newMember.employee_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newMember, "employee_number", $event.target.value)}}}),(errors[0])?_c('span',{staticClass:"text-danger slide-down-40-percent d-block z-n1"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1):_vm._e(),(_vm.registerType === _vm.eGroupRegisterType.USER_TEPM_REGISTER)?_c('div',{staticClass:"mail-address pt-3"},[_c('ValidationProvider',{attrs:{"rules":{
              required: true,
              email: true,
              max: 255,
              no_script_tag: true
            },"name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('common.form.mail_address'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t('common.form.require'))+" ")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control rounded-0 h--10 ",class:{ 'border-danger': errors[0] },attrs:{"placeholder":_vm.$t('common.form.mail_address'),"name":"email","type":"text"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),(errors[0])?_c('span',{staticClass:"text-danger slide-down-40-percent d-block z-n1"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1):_vm._e(),(_vm.registerType !== _vm.eGroupRegisterType.USER_TEPM_REGISTER)?_c('div',{staticClass:"password pt-3"},[_c('ValidationProvider',{attrs:{"rules":{
              required: true,
              check_password: true,
              max: 255,
              no_script_tag: true
            },"name":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var touched = ref.touched;
return [_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.registerType === _vm.eGroupRegisterType.ADMIN_REGISTER ? _vm.$t('common.form.temp_password') : _vm.$t('common.form.password'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t('common.form.require'))+" ")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newMember.password),expression:"newMember.password"}],staticClass:"form-control rounded-0 h--10 ",class:{ 'border-danger': (touched || invalid) && errors[0] },attrs:{"placeholder":_vm.$t('common.form.password'),"name":"password","type":"password","autocomplete":"new-password"},domProps:{"value":(_vm.newMember.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newMember, "password", $event.target.value)}}}),((touched || invalid) && errors[0])?_c('span',{staticClass:"text-danger slide-down-40-percent d-block z-n1"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1):_vm._e(),(
            _vm.registerType === _vm.eGroupRegisterType.USER_REGISTER_INVITE ||
              _vm.registerType === _vm.eGroupRegisterType.USER_REGISTER_REQUEST_JOIN
          )?_c('div',{staticClass:"password-confirm pt-3"},[_c('ValidationProvider',{attrs:{"rules":{
              required: true,
              check_password: true,
              max: 255,
              no_script_tag: true
            },"name":"password_confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var touched = ref.touched;
return [_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('common.form.password_confirm'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t('common.form.require'))+" ")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordConfirm),expression:"passwordConfirm"}],staticClass:"form-control rounded-0 h--10 ",class:{
                'border-danger':
                  ((touched || invalid) && errors[0]) || !_vm.passwordMatch
              },attrs:{"placeholder":_vm.$t('common.form.password_confirm'),"name":"password_confirm","type":"password","autocomplete":"new-password"},domProps:{"value":(_vm.passwordConfirm)},on:{"input":function($event){if($event.target.composing){ return; }_vm.passwordConfirm=$event.target.value}}}),((touched || invalid) && errors[0])?_c('span',{staticClass:"text-danger slide-down-40-percent d-block z-n1"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e(),(!_vm.passwordMatch && !((touched || invalid) && errors[0]))?_c('span',{staticClass:"text-danger slide-down-40-percent d-block z-n1"},[_vm._v(" "+_vm._s(_vm.$t('common.message.password_confirm_not_match'))+" ")]):_vm._e()]}}],null,true)})],1):_vm._e(),(_vm.registerType !== _vm.eGroupRegisterType.USER_TEPM_REGISTER)?_c('div',{staticClass:"fullname pt-3"},[_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('common.form.full_name'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t('common.form.require'))+" ")])]),_c('div',{staticClass:"d-flex"},[_c('ValidationProvider',{staticClass:"me-3",attrs:{"rules":{ required: true, max: 255, no_script_tag: true },"name":"first_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newMember.first_name),expression:"newMember.first_name"}],staticClass:"form-control rounded-0 h--10",class:{ 'border-danger': errors[0] },attrs:{"placeholder":_vm.$t('common.form.first_name'),"name":"first_name","type":"text"},domProps:{"value":(_vm.newMember.first_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newMember, "first_name", $event.target.value)}}}),(errors[0])?_c('span',{staticClass:"text-danger slide-down-40-percent d-block z-n1"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":{ required: true, max: 255, no_script_tag: true },"name":"last_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newMember.last_name),expression:"newMember.last_name"}],staticClass:"form-control rounded-0 h--10 ",class:{ 'border-danger': errors[0] },attrs:{"placeholder":_vm.$t('common.form.last_name'),"name":"last_name","type":"text"},domProps:{"value":(_vm.newMember.last_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newMember, "last_name", $event.target.value)}}}),(errors[0])?_c('span',{staticClass:"text-danger slide-down-40-percent d-block z-n1"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)]):_vm._e(),(_vm.registerType !== _vm.eGroupRegisterType.USER_TEPM_REGISTER)?_c('div',{staticClass:"alphabet-name pt-3"},[_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('common.form.full_name_alphabet'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t('common.form.require'))+" ")])]),_c('div',{staticClass:"d-flex"},[_c('ValidationProvider',{staticClass:"me-3",attrs:{"rules":{
                required: true,
                check_kana: true,
                max: 255,
                no_script_tag: true
              },"name":"first_name_kana"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newMember.first_name_kana),expression:"newMember.first_name_kana"}],staticClass:"form-control rounded-0 h--10",class:{ 'border-danger': errors[0] },attrs:{"placeholder":_vm.$t('common.form.first_name_alphabet'),"name":"first_name_kana","type":"text"},domProps:{"value":(_vm.newMember.first_name_kana)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newMember, "first_name_kana", $event.target.value)}}}),(errors[0])?_c('span',{staticClass:"text-danger slide-down-40-percent d-block z-n1"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":{
                required: true,
                check_kana: true,
                max: 255,
                no_script_tag: true
              },"name":"last_name_kana"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newMember.last_name_kana),expression:"newMember.last_name_kana"}],staticClass:"form-control rounded-0 h--10 ",class:{ 'border-danger': errors[0] },attrs:{"placeholder":_vm.$t('common.form.last_name_alphabet'),"type":"text","name":"last_name_kana"},domProps:{"value":(_vm.newMember.last_name_kana)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newMember, "last_name_kana", $event.target.value)}}}),(errors[0])?_c('span',{staticClass:"text-danger slide-down-40-percent d-block z-n1"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)]):_vm._e()]),_c('div',{staticClass:"flex-center pt-5"},[_c('button',{staticClass:"btn btn-primary text-light fs-15 rounded-5 w-100 mw--60 py-2",on:{"click":function($event){return handleSubmit(_vm.submitRegister)}}},[_vm._v(" "+_vm._s(_vm.$t('common.btn.btn_send'))+" ")])])]}}])})],1),_c('ModalSuccess',{attrs:{"successMess":_vm.modalMess},on:{"close":_vm.modalSuccessClose}}),_c('ModalError',{attrs:{"errorMess":_vm.modalMess}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }